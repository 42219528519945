import React from "react";
import "./Header.css";
import logo from "../imgs/others/logo-dobra-1.png";
import { Button } from "../buttons/Button";

function Header() {
  return (
    <header id="company-description">
      <div id="disclaimer">
        <h2>
         Administradora de Benefícios.
        </h2>
        <p>
          Plano de saude, odonto, empresarial, familiar e todos os tipos de
          seguros.
        </p>
      </div>
      <div id="subscription-forms">
        <p>Solicite sua cotação:</p>
        <form
          id="contatoform"
          action="https://formsubmit.co/b5a600b1a3dff1f54889a844c16ff97b"
          method="POST">
          <input
            type="hidden"
            name="_next"
            value="https://famigliaseguros.com/"
          />
          <input type="hidden" name="_captcha" value="false" />
          <div className="form-group">
            <input type="text" name="name" placeholder="Nome" required />
          </div>
          <div className="form-group">
            <input
              type="tel"
              name="phone"
              pattern="([0-9]{2})[0-9]{1}[0-9]{4}[0-9]{4}"
              placeholder="Telefone (DDD)999999999"
              required
            />
          </div>
          <div className="form-group">
            <input type="email" name="email" placeholder="E-mail" required />
          </div>
          <div className="form-group">
            <input type="text" name="city" placeholder="Cidade" required />
          </div>
          <div className="form-group">
            <small htmlFor="checkbox">Aceito ser contatado via Whatsapp</small>
            <input type="checkbox" name="checkbox" />
          </div>
          <input
            type="submit"
            className="btn--primary"
            value="SOLICITAR COTAÇÂO"
          />
        </form>
        <Button
          link="https://api.whatsapp.com/send?phone=556140422211"
          buttonStyle="btn--wpp"
          target="_blank">
          FALE AGORA PELO WHASTAPP
        </Button>
      </div>
    </header>
  );
}

export default Header;
