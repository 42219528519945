import React from "react";
import "./Navbar.css";
import logo from "../imgs/others/Logo.png";

function Navbar() {
  return (
    <div className="navbar">
      <h1> </h1>
      <a href="/">
        <img className="navbar-logo" src={logo} alt="Logo" />
      </a>
    </div>
  );
}

export default Navbar;
