import React from "react";
import { Link } from "react-router-dom";
import "./Button.css";

const STYLES = ["btn--primary", "btn--wpp"];

export const Button = ({ children, type, link, onClick, buttonStyle }) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  return (
    <div>
      <Link to={link} className="btn-mobile">
        <button
          className={`${checkButtonStyle}`}
          onClick={onClick}
          type={type}>
          {children}
        </button>
      </Link>
    </div>
  );
};
