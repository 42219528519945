import React, { useState, useEffect } from "react";
import "./FooterBar.css";

export default function FooterBar() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleWindowSizeChange() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <footer className="footer">
      <div className="footer-wpp">
        <a
          className="whatsapp-link-footer"
          href="https://web.whatsapp.com/send?phone=556140422211"
          rel="opener">
          (61) 4042-2211
          <br />
        </a>
        <p className="info-wpp">Somente WhatsApp</p>
      </div>
      {!isMobile && (
        <div className="footer-site">
          <p>www.napri.com.br</p>
        </div>
      )}
      <div className="footer-insta">
        <a href="https://www.instagram.com/famigliaseguros/" rel="opener">
          @Napri
        </a>
      </div>
    </footer>
  );
}
